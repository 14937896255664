<template>
  <div class="wrap_block">
<!--    :style="'height:' + blockHeight+ 'px'"-->
    <div class="body_block" ref="bodyBlock">
    <div class="page_title">
      <p style="color:#215476;font-weight: bold">
        <i style="font-size: 18px;color:#BEBEBE;margin-right: 4px" class="el-icon-shopping-cart-2"></i>采购清单</p>
    </div>
    <div class="right_top"  v-loading="loading"  element-loading-text="数据加载中" >
      <el-empty :style="{paddingTop: 20 +'px'}" description="无数据" v-if="!purchaseList.length "></el-empty>
      <el-table ref="purchaseList" max-height="450" :data="purchaseList" v-if="purchaseList.length > 0" border min-height="300">
        <el-table-column prop="inventory_id" label="存货编码" width="120" align="center" header-align="center">
          <template v-slot="{ row, $index }">
           <span :style="{color: row.is_first ? '#BD3124' : '' }">{{row.inventory_id}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="inventory_name" label="存货简称" min-width="150" align="left" header-align="center">
          <template v-slot="{ row, $index }">
            <span :style="{color: row.is_first ? '#BD3124' : '' }">{{row.inventory_name}}</span>
            <el-tag v-if="row.is_first" type="danger" size="mini" style="background: #BD3124;color:#fff;" class="new_pro_tag"> 新购</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="数量" width="180" align="center" header-align="center" :key="Math.random()">
          <template v-slot="{ row, $index }">
            <el-input-number :key="$index" v-model.number="row.quantity" @blur="validateInput(row,$index)" @change="handleCountChange(row)" size="mini" type="mumber" :min="1" label=""></el-input-number>
            <span>&nbsp;&nbsp;{{row.sale_unit}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tax_price" label="单价" width="100" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <span >{{row.tax_price ? row.tax_price : '0'}}&nbsp;元</span>
          </template>
        </el-table-column>
        <el-table-column prop="total_price" label="总价" width="120" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <span >{{row.total_price ? row.total_price : '0.00'}}&nbsp;元</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="60" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <span @click="handleDelete($index,row)">
                <el-icon class="el-icon-delete" type="text" size="small" style="color:#BD3124" >删除</el-icon>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="purchaseList.length > 0" style="margin-right: 20px">
        <p class="total_pirce" style="color:#6C6C6C;font-weight: bold">合计总额：<span style="color:#BD3124;">{{total}}</span>&nbsp;元</p>
      </div>
    </div>
    <div class="right_block" :style="blockStyle">
      <div class="right_bottom">
        <div class="page_title">
          <p style="color:#215476;font-weight: bold">
            <i style="font-size: 18px;color:#BEBEBE;margin-right: 4px"
               class="el-icon-tickets" ></i>开票信息</p>
        </div>
        <div class="bt_content">
          <el-form :model="invoice_data" ref="invoice_data" label-width="100px" size="mini" :rules="rules">
            <el-col :span="12">
              <el-form-item label="开票抬头：" class="invoice_label invoice_label_top" prop="invoice_header">
                <el-input v-model="invoice_data.invoice_header" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司税号：" class="invoice_label invoice_label_top" prop="registration_code">
                <el-input v-model="invoice_data.registration_code" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收票人：" class="invoice_label" prop="contact_person">
                <el-input v-model="invoice_data.contact_person" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="接收邮箱：" class="invoice_label" prop="contact_email">
                <el-input v-model="invoice_data.contact_email" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </div>
      </div>
      <div class="right_bottom">
        <div class="page_title">
          <p style="color:#215476;font-weight: bold"><i style="font-size: 18px;color:#BEBEBE;margin-right: 4px" class="el-icon-location-outline"></i>收货信息</p>
        </div>
        <div class="bt_content">
          <el-form :model="address_data" ref="address_data" label-width="100px" size="mini" :rules="rules">
            <el-col :span="24">
              <el-form-item label="是否自提：" class="address_label address_label_first" >
                <el-radio-group v-model="delivery_type" @change="changeDeliveryType">
                  <el-radio :label="4002">否</el-radio>
                  <el-radio :label="4001">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <div >
              <el-col :span="24">
                <el-form-item :label="delivery_type== 4001 ? '提货人：': '收货人：'" prop="customer_contact" class="address_label">
                  <el-input v-model="address_data.customer_contact" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="电话：" prop="customer_phone" class="address_label">
                  <el-input v-model="address_data.customer_phone" placeholder="请输入内容" @input="trimInput(address_data.customer_phone)"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="18">
                <el-form-item label="地址：" prop="customer_address" class="address_label">
                  <el-input v-model="address_data.customer_address" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
            </div>
          </el-form>
        </div>
      </div>
      <div class="right_bottom">
        <div class="page_title">
          <p style="color:#215476;font-weight: bold"><i style="font-size: 18px;color:#BEBEBE;margin-right: 4px" class="el-icon-document"></i>备注说明</p>
        </div>
        <div class="bt_content" style = "padding: 20px;">
          <el-form :model="form" ref="form_data" label-width="0" size="mini" :rules="rules">
              <el-form-item  class="address_label address_label_first">
               <el-input type="textarea" v-model="form.order_remark" placeholder="请输入说明内容" :rows="4"></el-input>
              </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
      <el-dialog
          title=""
          :visible.sync="relateDialogVisible"
          v-if="relateDialogVisible"
          class="relate_dialog"
          width="50%"
          custom-class="new_confirm_dialog"
          :show-close="true"
          :before-close="handleClose">
        <relate-dialog ref="newConfirm" :inventory_id="selected_inventory_id" :newProductList="hasRelate" :relateTableData=" relateTableData" @close="relateDialogVisible =false" @updateCart="updateCartAndGoods"></relate-dialog>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="newProDialogVisible"
        width="40%"
        custom-class="new_confirm_dialog"
        :show-close="false"
        :before-close="handleClose">
        <template #title>
          <p style="color:#4F4F4F;font-size: 18px;padding-bottom: 14px;border-bottom: 2px dotted #eee">首次订购商品确认</p>
        </template>
        <newConfirm ref="newConfirm" :newProductList="purchaseList"></newConfirm>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="save(2)" size="mini">确认</el-button>
          <el-button @click="closeNewDialog" size="mini">取消</el-button>
        </span>
      </el-dialog>
      <el-dialog
          :visible.sync="confirmDialogVisible"
          width="70%"
          class="confirm_dialog"
          :show-close="false"
          :before-close="handleClose">
        <template #title>
          <!-- 这里是标题内容 -->
          <p style="color:#215476;font-weight: bold">
            <i style="font-size: 18px;color:#BEBEBE;margin-right: 4px"
               class="el-icon-shopping-cart-2"></i>采购清单</p>
        </template>
          <orderConfirm ref="orderConfirm" v-if="confirmDialogVisible" :formData="formdata"></orderConfirm>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" :disabled="isSubmitDisable" @click="submit" size="mini">确认提交</el-button>
          <el-button @click="confirmDialogVisible = false" size="mini">返回修改</el-button>
        </span>
      </el-dialog>
      <successTip ref="successTip" v-if="isSuccessTip" :orderId="this.newOrderId" @checkOrder="checkOrder" @updateData="updateData"></successTip>
    </div>
    <div class="footer">
      <el-button @click="save(1)" type="primary" style="background: #347CAF" size="mini" :disabled="loading">确认提交</el-button>
      <p class="tips_text" >注：本次新购商品表示历史订单中未曾有历史订购信息，需仔细确认本次提交是否正确</p>
    </div>
    <el-dialog :visible.sync="authenVisible" :show-close=" authenStatus== 5003 ? true : false" @close="handleAuthenClose" :close-on-click-modal="false">
      <div class="authen_box">
        <img src="@/assets/img/cert.png" alt="" v-if="authenStatus !== 5003">
        <img src="@/assets/img/failTip.png" class="fail" alt="" v-if="authenStatus == 5003">
        <p v-if="authenStatus== 5004">您还未完成客户认证，认证成功后可进行相关操作</p>
        <p v-if="authenStatus== 5001">客户认证正在审核中，请稍后再来！</p>
        <p v-if="authenStatus== 5003">客户认证失败，请稍后重试！</p>
        <div class="authen_button" v-if="authenStatus== 5004">
          <el-button type="primary" @click="goAuthen">立即认证</el-button>
          <el-button @click="goPrePage">稍后认证</el-button>
        </div>
        <p class="text">如有其他疑问，可联系商务进行沟通，商务QQ：3007359468/3007359467</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import common from "@/views/OrderManagement/mixin/common";
import newConfirm from "@/views/OrderManagement/custom/components/newConfirm.vue"
import orderConfirm from  "@/views/OrderManagement/custom/components/orderConfirm.vue"
import {checkCard, createOrder, getCustomInfo, deleteCart, updateCart, updateCartNum,getRelateInventory} from "@/api/orderGoods/custom";
import successTip from "@/views/OrderManagement/custom/components/successTip.vue"
import relateDialog from "@/views/OrderManagement/custom/components/relateDialog.vue";
export default {
  mixins: [common],
  components: {newConfirm,orderConfirm,successTip,relateDialog},
  data() {
    var checkPhone = (rule, value, callback) => {
      let reg = /^1\d{10}$|^(0\d{2,3}-?|\(0\d{2,3}\))?[1-9]\d{4,7}(-\d{1,8})?$/
      if (value === '') {
        callback(new Error('请输入电话'));
      } else {
        if (!reg.test(value)) {
          callback(new Error('请输入正确的电话'));
        }
        callback();
      }
    };
    var checkEmail = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value === '') {
        callback(new Error('请输入邮箱'));
      } else {
        if (!reg.test(value)) {
          callback(new Error('请输入正确的邮箱'));
        }
        callback();
      }
    };
    var checkRegcode = (rule, value, callback) => {
      let reg1 = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/
      if (value === '') {
        callback(new Error('请输入税号'));
      } else {
        if (reg1.test(value) ) {
          callback();
        }else{
          callback(new Error('请输入正确的税号'));
        }
        callback();
      }
    };

    return {
      relateDialogVisible:false,
      isClickable: false,
      isSubmitDisable: false,
      isSuccessTip: false, // 成功弹窗是否显示
      newOrderId: '', //新生成的订单id
      invoice_header_temp: '', //用来对比是否有更改校验
      selected_inventory_id: '',
      isShowConfirm: false,
      purchaseList: [],
      newProductList: [],
      hasRelate: [],
      confirmDialogVisible: false,
      newProDialogVisible: false,
      relateTableData:[],
      hasRelateData: false,
      formdata: {
        isInvoiceHeaderValid: true,
      },
      form:{
        order_remark: '',
      },
      loading: false,
      tabelHeight: 0,
      blockHeight: 0,
      isHight: false,
      total: 0,
      delivery_type: 4002,
      inventory_data: [],
      invoice_data:{
        invoice_header: '',
        registration_code: '',
        contact_person: '',
        contact_email: '',
      },
      address_data:{
        customer_contact: '',
        customer_phone: '',
        customer_address: '',
      },
      authenVisible: false,
      authenStatus: 5002,
      rules: {
        invoice_header: [
          { required: true, message: '请输入开票抬头', trigger: 'blur' },
        ],
        registration_code: [
          { required: true, message: '请输入公司税号', trigger: 'blur' },
          {validator: checkRegcode,trigger: 'blur', }
        ],
        contact_person: [
          { required: true, message: '请输入收票人', trigger: 'blur' },
        ],
        contact_email: [
          { required: true, message: '请输入接收邮箱', trigger: 'blur',  },
          {validator: checkEmail,trigger: 'blur', }
        ],
        customer_contact: [
          { required: true, message: '请输入收货人', trigger: 'blur' },
        ],
        customer_phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {validator: checkPhone,trigger: 'blur',}
        ],
        customer_address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
        ],
       }
    }
  },
  computed: {
    // 计算属性，返回一个包含多个样式属性的对象
    blockStyle() {
      return {
        position: this.isHight ? 'absolute' : '',
      };
    },
  },
  watch: {
    'address_data.customer_phone'(newValue) {
      this.address_data.customer_phone = newValue.trim();
    }
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if(userInfo){
      this.invoice_header_temp = userInfo.organization_name
    }else{
      this.invoice_data.invoice_header = ''
    }
  },
  created() {
    this.loading = true
    this.getTableHeight()
    this.getCartList()
    this.getDetail()
  },
  methods: {
    handleAuthenClose(){
      this.authenVisible = false
      this.$router.push('/SearchPage')
    },
    updateCartAndGoods(){
      this.$emit('updateData')
      this.getCartList()
    },
    updateCart(){
      this.$emit('updateCart')
    },
    validateInput(row,index) {
      const intValue = Math.floor(row.quantity);
      if (isNaN(intValue) || intValue <= 0) {
        this.$set(this.purchaseList[index], 'quantity', 1)
      } else {
        this.$set(this.purchaseList[index], 'quantity', intValue)
      }
      let cart = []
      let shop_item = {
        inventory_id: row.inventory_id,
        quantity: Number(row.quantity)
      }
      updateCartNum(shop_item).then(res=>{
        this.getCartList()
      })
      this.$forceUpdate()
    },
    handleCountChange(row){
      let shop_item = {
        inventory_id: row.inventory_id,
        quantity: Number(row.quantity)
      }
      updateCartNum(shop_item).then(res=>{
        this.getCartList()
      })
      this.$forceUpdate()
    },
    changeDeliveryType(){
      this.getDetail('adr')
    },
    getDetail(type){
      getCustomInfo({delivery_type: this.delivery_type}).then(res=>{
        this.authenStatus = res.data.invoice_info.is_certified
        if([5001,5003,5004].includes(this.authenStatus)){
          this.authenVisible = true
        }
        if(type === 'adr'){ // 切换是否自提只更新收货信息
          this.address_data = res.data.address_info
        }else{
          this.address_data = res.data.address_info
          this.invoice_data = res.data.invoice_info
        }
      })
    },
    updateData(){
      this.$emit('updateData')
      this.getCartList()
      this.$nextTick(() => {
        // 获取表格的 DOM 元素
        const blockWrapper = this.$refs.bodyBlock
        window.requestAnimationFrame(() => {
          blockWrapper.scrollTop = 0;
        });
      });
    },
    getCartList(val,type) { // type= 1推荐列表 0商品列表 del删除
      this.loading = true
      setTimeout(() => {
        checkCard().then(res => {
          this.purchaseList = res.data
          if(type === 'del'){  // 删除完成再提示
            this.$message.success('删除成功')
          }
          if(this.purchaseList.length > 0){
            this.total= this.purchaseList.reduce((prev, curr) => {
              return prev + Number(curr.total_price)
            }, 0)
            if(this.total || this.total === 0){
              this.total = this.total.toFixed(2);
            }
            if(val){ // 单条订购
              setTimeout(() => {
                // 获取表格的 DOM 元素
                const blockWrapper = this.$refs.purchaseList.$el.querySelector('.el-table__body-wrapper');
                window.requestAnimationFrame(() => {
                  blockWrapper.scrollTop =  blockWrapper.scrollHeight
                });
              },1000)
              this.hasRelate = this.purchaseList.filter(item => item.inventory_id === val)
              let isShowRelate  = false
              if( this.hasRelate.length > 0){
                isShowRelate =  this.hasRelate[0].is_relate
                this.selected_inventory_id =  this.hasRelate[0].inventory_id
              }
              if(isShowRelate && type == '0'){
                getRelateInventory({inventory_id: val}).then(res =>{
                  if(res.data && res.data.length > 0){
                    this.relateTableData = res.data
                    this.relateTableData.forEach(item=>{
                      item.isOrder = false
                    })
                    this.relateDialogVisible = true
                  }
                }).catch(err =>{

                }).finally(()=>{

                })
              }
            }
          }
          this.loading = false
        }).finally(()=>{
          this.loading = false
          this.isClickable = true
        })
      },1000)

    },
    handleNumChange(row){
      // row.quantity = Math.floor(row.quantity);
      // row.total_price= (Number(row.tax_price) * row.quantity).toFixed(2)
      // this.total= this.purchaseList.reduce((prev, curr) => {
      //   return prev + Number(curr.total_price)
      // }, 0)
      // this.total = this.total.toFixed(2)
    },
    save(type){
      if(this.purchaseList.length){
        this.purchaseList.forEach(item => {
          item.price = item.total_price
          item.inventory_id = Number(item.inventory_id)
        })
      }else{
        this.$message.error('请先选购所需商品！')
        return
      }
      this.invoice_data.customer_id = JSON.parse(localStorage.getItem('userInfo')).organization
      this.address_data.customer_id = JSON.parse(localStorage.getItem('userInfo')).organization
      this.address_data.customer_title = JSON.parse(localStorage.getItem('userInfo')).organization_name
      this.formdata = {
        delivery_type: this.delivery_type, //交货方式
        inventory_data: this.purchaseList, //商品信息
        invoice_data: this.invoice_data, //开票信息
        address_data: this.address_data, //收货信息
        total: this.total, //收货信息
        order_remark: this.form.order_remark,
      }
      let hasNewProduct = false
      this.purchaseList.some(item => {
        if(item.is_first){
          hasNewProduct = true
        }
      })
      this.$refs['invoice_data'].validate((valid) => {
        if(valid){
          this.$nextTick(()=> {
            this.$refs['address_data'].validate((valid2) => {
              if(valid2){
                if(this.invoice_header_temp !== this.invoice_data.invoice_header){
                  this.formdata.isInvoiceHeaderValid = false
                }else{
                  this.formdata.isInvoiceHeaderValid = true
                }
                if(hasNewProduct && type ===1){
                  this.newProductList = []
                  this.purchaseList.forEach(item => {
                    if(item && item.is_first){
                      this.newProductList.push(item)
                    }
                  })
                  this.newProDialogVisible = true
                }else{
                  this.newProDialogVisible = false
                  this.confirmDialogVisible = true
                }
              }else{
                this.$message.error('请检查必填字段！')
                return false
              }
            })
          })
        }else{
          this.$message.error('请检查必填字段！')
          return false
        }
      })

    },
    submit(){
      this.isSubmitDisable = true
      let params = {
        delivery_type: this.delivery_type, //交货方式
        inventory_data: this.purchaseList, //商品信息
        invoice_data: this.invoice_data, //开票信息
        address_data: this.address_data, //收货信息
        order_remark: this.form.order_remark
      }
      createOrder(params).then(res => {
        this.confirmDialogVisible = false
        if(res.code === 200){
          this.isSuccessTip = true
          this.newOrderId = res.data
          this.isSubmitDisable = false
          setTimeout(() => {
            this.$refs.successTip.dialogVisible = true
          },500)
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    checkOrder(){
      localStorage.setItem('checkOrderId',this.newOrderId)
      this.$router.push({path: '/odrMgt/custom/ordList'})
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    handleDelete(index,row){
      this.$confirm('确定要删除当前商品吗?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        // center: true
      }).then(() => {
        let params = {
          inventory_ids: [Number(row.inventory_id)]
        }
        deleteCart(params).then(res=>{
          if(res.code == 200){
            this.getCartList('','del')
            this.$emit('updateData')
          }else{
            this.$message.error(res.data.msg)
          }
        })
        // this.purchaseList.splice(index, 1)
      }).catch(() => {
      });
    },
    closeNewDialog(){
      this.newProDialogVisible = false
      this.newProductList = []
    },
    getTableHeight(){
      var viewportHeight = window.innerHeight;
      // var viewportWidth =  window.innerWidth
      // var element = document.getElementById('pagination');
      this.blockHeight = viewportHeight -220
    },
    goAuthen(){
      this.$router.push({path: '/authen'})
    },
    goPrePage(){
      this.$router.push('/SearchPage')
      // this.$router.go(-1)
    },
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/order";
::v-deep .el-form-item.is-error .el-input__inner{
  border: 2px solid #f56c6c;
}
::v-deep .el-form-item.address_label .el-form-item__error{
  top: 30%;
  left: 256px;
  font-size: 13px;
}
::v-deep .el-form-item.invoice_label_top .el-form-item__error{
  top: -50%;
  font-size: 13px;
}

::v-deep .el-textarea__inner::placeholder {
  color: #C0C4CC;
  font-weight: bold;
}
::v-deep .el-textarea__inner {
  color: #888888;
  font-weight: bold;
}
.confirm_dialog {
  display: flex; padding: 20px;
  ::v-deep .el-dialog { margin: auto !important; }
}
::v-deep .el-input-number--mini{
  width: 110px;
}
::v-deep .el-dialog__body{
  padding: 18px 0;
  .right_top{
    padding: 0 20px;
  }
  .right_bottom{
    .page_title{
      padding: 4px 10px;
      border-bottom:1px dotted #BBBBBB;
    }
    .bt_content{
      width: 100%;
      padding:10px 0;
      box-sizing: border-box;
    }
  }
}
::v-deep .confirm_dialog .el-dialog__header {
  border-bottom: 1px dotted #BBBBBB!important;
}
::v-deep .el-table th.el-table__cell>.cell{
  color:#000;
}
::v-deep .el-table thead th.el-table__cell{
  background: #E8E8E8;
  color: #000;
}
::v-deep .el-table--border{
  border: 1px solid #BBBBBB;
}
::v-deep .el-table--border .el-table__cell,{
  border-right: 1px solid #BBBBBB;
}
::v-deep .el-table td.el-table__cell{
  border-bottom: 1px solid #BBBBBB;
}
.body_block{
  overflow-y: scroll;
  height: calc(100% - 110px);
  position: relative;
}
.total_pirce{
  text-align: right;
  margin:10px 0;
}
::v-deep .new_confirm_dialog {
  .el-dialog__body{
    padding: 10px 20px;
    .el-table thead th.el-table__cell:nth-child(3) {
      border-right: none!important;
    }
  }
}
.relate_dialog{
    display: flex;
    padding: 20px;
  ::v-deep .el-dialog { margin: auto !important; }
}
::v-deep .invoice_label .el-form-item__label{
    color:#000;
}
::v-deep .invoice_label .el-input.el-input--mini .el-input__inner{
  color: #888888;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .el-dialog__body .right_bottom .page_title p{
    font-size: 16px!important;
  }
  ::v-deep .el-dialog__body .right_bottom .page_title p i{
    font-size: 18px!important;
  }
  ::v-deep .confirm_dialog .el-dialog__header p {
    font-size: 18px!important;
  }
  ::v-deep .confirm_dialog .el-dialog__header p i {
    font-size: 18px!important;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
      font-size: 14px;
    }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 14px;
  }
  .body_block .page_title{
    font-size: 14px;
  }

  ::v-deep .el-input--mini .el-input__inner{
    width: 200px;
    font-size: 14px;
  }
  ::v-deep .right_top .el-input--mini .el-input__inner{
    width: 110px;
  }
  ::v-deep .address_label .el-input--mini  .el-input__inner{
    font-size: 13px;
  }
  ::v-deep .el-form-item__label{
    font-size: 14px;
  }
}
@media screen and (min-width: 1551px) , print {
  ::v-deep .el-dialog__body .right_bottom .page_title p{
    font-size: 18px!important;
  }
  ::v-deep .el-dialog__body .right_bottom .page_title p i{
    font-size: 20px!important;
  }
  ::v-deep .confirm_dialog .el-dialog__header p {
    font-size: 20px!important;
  }
  ::v-deep .confirm_dialog .el-dialog__header p i {
    font-size: 20px!important;
  }
  ::v-deep .el-form-item__label{
    font-size: 16px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 16px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 16px;
  }
  .body_block .page_title{
    padding: 10px ;
    font-size: 16px;
  }
  .total_pirce{
    font-size: 16px;
  }
  ::v-deep .el-input--mini .el-input__inner{
    width: 250px;
    font-size: 16px;
  }
  ::v-deep .right_top .el-input--mini .el-input__inner{
    width: 110px;
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .address_label .el-input--mini  .el-input__inner{
    font-size: 14px;
  }
  ::v-deep .dialog_content{
    //max-height: 620px;
    overflow-y: auto!important;
  }
}

.body_block{
  position: relative;
  width: 100%;
  box-sizing: border-box;
  //padding: 0 14px;
  .page_title{
    width: 100%;
    box-sizing: border-box;
    padding: 10px ;
    border-bottom: 1px dotted #BEBEBE;
  }
  .right_top{
    display: inline-block;
    width: calc(100% - 22px);
    margin:14px 0 14px 16px;
  }
  .right_block{
    //position: fixed;
    //width: 100%;
    bottom: 20px;
    .right_bottom{
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      .bt_content{
        width: 100%;
        box-sizing: border-box;
        padding:  20px 0;
        display: inline-block;
      }
    }
  }
}

::v-deep .address_label .el-form-item__label{
  color: #6C6C6C;
}
::v-deep .address_label_first .el-form-item__label{
  color: #000;
}
::v-deep .address_label .el-input__inner{
  color: #888888;
  font-size: 14px;
}
.dialog-footer{
  display: block;
  width: 100%;
  text-align: center;
}
.wrap_block{
  height: 100%;
}
.footer{
  position: absolute;
  bottom: 30px;
  width:calc(49.5% - 14px);
  text-align: center;
}
.tips_text{
  font-size: 13px;
  color:#BD3124;
  margin:10px 0 20px 0;
}
.authen_box{
  text-align: center;
  img{
    width: 294px;
    height: 167px;
    margin-bottom: 40px;
  }
  img.fail{
    width: 185px;
    height: 185px;
  }
  p{
    font-size: 28px;
    color: #101010;
    margin-bottom: 30px;

  }
  .authen_button{
    margin-bottom: 20px;
    ::v-deep .el-button{
      width:300px;
      height: 50px;
      line-height: 50px;
      padding: 0;
      font-size: 18px;
    }
  }
  p.text{
    font-size: 20px;
  }
}
</style>